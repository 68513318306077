import React, { useEffect, useState,useContext } from 'react' 
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Toast } from 'react-bootstrap';
import axios from 'axios';
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';
import { AuthContext } from '../../context/AuthContext';
import Alert from '@mui/material/Alert';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CircularProgress from '@mui/material/CircularProgress';
const TEST = () => {
  const [toast, setToast] = useState(false);
const {user}=useContext(AuthContext);
  const [details, setDetails] = useState();
const [file,setFile]=useState(null);

const[toastData,setToastData]=useState('');
  const Payment = async () => {
    const data = new FormData()

    const res = await axios.post(ENDPOINT + "get-scanner-details.php", data, SecurityHeaders);

    if (res) {
      if (res.data.status === "success") {
        setDetails(res.data.data)
      }
    }
  }
  useEffect(() => {
    Payment()
  }, [])

  const [loading,setloading] = useState(false)

  // upload screenshots
  const params = useParams()
const payment_id = params.payment_id
  const uploadScreenShots = async (e) => {
    e.preventDefault();
    setloading(true)
    // Correct the condition to check for valid file types
    if (file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "image/jpg") {
      setToastData('Upload images only in these formats jpeg, png, jpg');
      setToast(true);
      return;
    }
    
    try {
      const data = new FormData();
      data.append("session", user.SessionId);
      data.append("id_photo", file);
      data.append("payment_id",payment_id)
  
      const res = await axios.post(`${ENDPOINT}upload-payment-screen-shot.php`, data, SecurityHeaders);
  
      if (res) {
        setloading(false)
        if (res.data.status === "success") {
          setToastData('Upload Successfully');
          setToast(true);
        }
      }
    } catch (error) {
      setToastData('Something Went Wrong');
      setToast(true);
    }
  };
  
console.log(file);

  return (
    <>
      <div className="position-fixed bottom-60 left-0 w-100 justify-center">
        <Toast className="bg-warning" onClose={() => setToast(false)} show={toast} delay={3000} autohide>
          <Toast.Header className="bg-warning text-dark">
            <img
              src="holder.js/20x20?text=%20"
              className="rounded bg-warning me-2"
              alt=""
            />
            <strong className="me-auto">{toastData}</strong>

          </Toast.Header>
        </Toast>

      </div>


      <div className='container col-lg-8 col-md-10 col-sm-12 col-12 mx-sm-auto rounded-3 bg-white shadow-none md-shadow mt-2 mt-md-5 mb-5'>

        <div className='p-0 md-p-5 d-flex justify-content-between  flex-wrap mx-sm-auto'>

          {
          details ? <>
           
          <div className='col-lg-5 col-md-6 col-sm-12 col-12 mx-sm-auto'>
          <div  className=' col-12 d-flex justify-content-sm-center'>
          <div className='col-lg-11 col-md-9 col-sm-8 col-12'>
            <div className=' col-12'>
              <img className='w-100 p-0  ps-0' src={details.scanner_url} />
            </div>
            <div className='col-12 mt-2'>
         
              <div className="d-flex align-items-center">
              <h5 className='m-0 p-0'>Acc Name : {" "} </h5>
             <p className='m-0 p-0 ms-2'> {"  " + details.name}</p>
              </div>
              <div className='d-flex m-2 ms-0'>
                <h6>UPI-ID :{" "+details.upi_id}</h6>
               
                <CopyToClipboard onCopy={() => {setToastData('Copied UPI ID') ;setToast(true);}} text={details.upi_id}  >

                  <ContentCopyIcon className='ms-3' />

                </CopyToClipboard>
              </div>
            </div>
          </div>
          </div>
          </div>


          </>:null
          }
         
          <div className='col-lg-7 col-md-6 col-sm-12 col-12 '>
            <div >
            <Alert variant="filled" className='mb-3 mt-3 mt-lg-0 mt-md-0' severity="info">
      Our team will contact you within 24 hours once you have uploaded your payment screenshot.
      </Alert>
              <h4>Follow these Instructions</h4>
              <ol>
                <li>Scan the QR code</li>
                <li>Make the Payment</li>
                <li>Take the Screenshot of your Payment</li>
                <li>Upload your Screenshot</li>
                <li>Please remember to upload images only in the following formats: JPEG, PNG, or JPG.</li>
              
              </ol>
            </div>

      
           <input id="payment-button" className='d-none' onChange={(e)=>setFile(e.target.files[0])} type='file'/>
            <div className=' d-flex justify-content-start   col-12'>
     
     
            <button disabled={loading} onClick={()=>{document.getElementById('payment-button').click()}}  className='btn me-2 btn-primary  mt-3'>{file?"Change Photo":"Upload Screenshot"}</button>

         {file?
         <>
             <button  onClick={uploadScreenShots} disabled={loading} className='btn btn-success  mt-3'> {loading?<><CircularProgress size={20} className='text-light'/> Uploading... </>:"Complete Registration"} </button>
        
         </>:null
         }
         
              </div>
      
       
          </div>


        </div>


      </div></>

  )
}

export default TEST