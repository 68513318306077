import { Avatar } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import GetCourseBatch from "../../../actions/GetCourseBatch";
import { AuthContext } from "../../../context/AuthContext";
import SearchIcon from "@mui/icons-material/Search";
import Localbase from "localbase";

const CourseLeaderboard = ({ more }) => {
  const params = useParams();
  const course_url = params.course_url;
  const batch_id = params.batch_id;

  let db = new Localbase("db");
  const { user } = useContext(AuthContext);
  const [batch, setBatch] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const GetBatch = async () => {
    const res = await GetCourseBatch({
      payload: {
        course_url: course_url,
        limit: 100,
        batch_id: batch_id,
        user: user.SessionId,
      },
    });
    if (res) {
      if (res.status === "success" && res.team !== null) {
        setBatch(res.team);
        setInitialData(res.team);
        setResponse(res.team);

        db.collection("paths").add(
          {
            batch: res.team,
          },
          "batch",
        );
      }
    }
  };

  useEffect(() => {
    db.collection("paths")
      .doc("batch")
      .get()
      .then((batchDoc) => {
        if (batchDoc.batch) {
          setBatch(batchDoc.batch);
          setInitialData(batchDoc.batch);
          setResponse(batchDoc.batch);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from Localbase", error);
      });

    GetBatch();
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (initialData) {
      const filteredData = initialData.filter((item) => {
        return (
          item.first_name.toLowerCase().includes(term) ||
          item.last_name.toLowerCase().includes(term) ||
          item.username.toLowerCase().includes(term)
        );
      });

      setResponse(filteredData);
    }
  };

  return (
    <div className="w-100 p-lg-3 p-md-3 p-sm-3 p-1 rounded section sm-mt-50">
      {batch.length > 0 ? (
        <>
          <div className="d-flex justify-content-between">
            {more ? (
              <Link to={`/leaderboard/${course_url}`}>View All</Link>
            ) : null}
          </div>

          <div className="d-flex">
            <div className="position-relative my-3 col-12">
              <input
                onChange={handleSearch}
                value={searchTerm}
                placeholder="Search"
                className="w-100 form-control leaderboard-search col-lg-12"
              ></input>
              <SearchIcon className="text-black leaderboard-search-icon mt-2" />
            </div>
          </div>

          <div>
            <div
              style={{ color: "#1e1e2f", borderRadius: "20px" }}
              className="border rounded-3 bg-leaderboard"
            >
              <div className="headings d-flex border-bottom py-2 justify-content-evenly rounded-top-2">
                <div className="col text-light ms-2 ">
                  <b>Name</b>
                </div>
                <div className="col text-light  text-center ">
                  <b>Points</b>
                </div>
                <div className="col text-light text-center ">
                  <b>Attendance</b>
                </div>
                <div className="col text-light text-center ">
                  <b>Rank</b>
                </div>
              </div>

              <>
                {response.slice(3).map((item, index) => (
                  <div className="rowss border-bottom p-3 text-center  ">
                    <div className="col text-light d-flex  m-0  ">
                      <Avatar src={item.profile_pic}></Avatar>
                      <p className="ms-2 mt-2 text-light">{item.first_name}</p>
                    </div>

                    <div className="col">
                      <div>
                        <p className="text-light">{item.points ? item.points : 0}</p>
                      </div>
                      <div
                        style={{
                          borderTop: "1px dashed",
                          width: "26%",
                          margin: "0 auto",
                          marginTop: "2px",
                          color: "red",
                        }}
                      ></div>
                    </div>
                    <div className="col">
                      <div>
                        <p className="text-light">{item.attendance ? item.attendance : "NA"}</p>
                      </div>
                      <div
                        style={{
                          borderTop: "1px dashed",
                          width: "46%",
                          margin: "0 auto",
                          marginTop: "2px",
                          color: "green",
                        }}
                      ></div>
                    </div>
                    <div className="col">
                      <div>
                        <p className="text-light">{item.rank?item.rank:"NA"}</p>
                      </div>
                      <div
                        style={{
                          borderTop: "1px dashed",
                          width: "6%",
                          margin: "0 auto",
                          marginTop: "2px",
                          color: "blue",
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </>
            </div>
          </div>
        </>
      ) : (
        <>
          <h4>No Students Found!</h4>
          <p>It seems like no student has joined the community yet.</p>
        </>
      )}
    </div>
  );
};

export default CourseLeaderboard;
