import React, {useState} from "react";
import Rating from "@mui/material/Rating";
import axios from "axios";
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';



const Feedbackpage = () => {
const [fullName, setFullName] = useState(null);
const [email, setEmail] = useState(null);
const [phone, setPhone] = useState(null);
const [message, setMessage] = useState(null);
const [rating, setRating] = useState(0);
const [checked, setChecked] = useState(false);

const feedbackForm = async (event) => {

  const data = new FormData();
  data.append("full_name", fullName);
  data.append("email", email);
  data.append("phone", phone);
  data.append("message", message);
  data.append("rating", rating);
  data.append("checked", checked);

  try {
    const res = await axios.post(
      ENDPOINT + `event-feedback.php`,
      data,
      SecurityHeaders,
    );

    if (res.data.status === "true") {
      alert("Account exists!");
    } else {
      alert("Feedback submitted successfully.");
    }
  } catch (error) {
    alert("Error occurred while submitting feedback.");
  }
};


  return (
    <div>
      <div className="container d-flex justify-content-center">
        <div className="col-lg-5 col-md-8 col-sm-10 col-12 shadow rounded p-3 my-5">
          <h2 className="text-center mb-4 mt-2">Feedback Form</h2>

          <form onSubmit={(e)=>{e.preventDefault();feedbackForm();}}>
            <div className="mb-3">
              <label className="form-label">Full Name *</label>
              <input
                type="text"
                className="form-control"
                name="fullName"
                value={fullName}
                required
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Full Name"
             
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Email Address *</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email@example.com"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Phone Number *</label>
              <input
                type="tel"
                className="form-control"
                name="phoneNumber"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="123-456-7890"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Feedback</label>
              <textarea
                className="form-control"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Please describe your feedback..."
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Rating *</label>
              <div className="rating">
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Do you want to join the course or internship? *
              </label>
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                  <label className="form-check-label">
                    Yes, I am interested
                  </label> 
                </div>
              </div>
            </div>

            <button
              type="submit"
            
              className="btn btn-primary w-100"
            >
              Submit Feedback
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Feedbackpage;
