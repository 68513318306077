import axios from "axios";
import React from "react";

import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";

import SearchIcon from "@mui/icons-material/Search";

import { useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import { useState } from "react";
import Toast from "react-bootstrap/Toast";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import dateFormat from "dateformat";
import { useContext } from "react";
import styled from "styled-components";
import { AuthContext } from "../../context/AuthContext";
import ENDPOINT from "../../data/ENDPOINT";
import SecurityHeaders from "../../data/SecurityHeaders";
import SetPage from "../../data/SetPage";
import CourseSuggestions from "../community/CourseSuggestions";
import WorkshopsSuggestions from "../dashboard/WorkshopsSuggestions";
import ModalStyle from "../../data/ModalStyle";
import DetectDevice from "../../data/DetectDevice";
import { Close } from "@material-ui/icons";
import { Link } from "react-router-dom";
import earningsicon from "../../assets/images/wallet/earning.svg";
import quizicon from "../../assets/images/wallet/quiz.svg";
import withdrawlicon from "../../assets/images/wallet/withdraw.svg";
import balanceicon from "../../assets/images/wallet/balance.svg";
import communityicon from "../../assets/images/wallet/community.svg";
import referralicon from "../../assets/images/wallet/refer.svg";
import HackingInternshipBanner from "../dashboard/HackingInternshipBanner";
import { Helmet } from "react-helmet";
import SimpleNotificationModal from "../../components/modals/SimpleNotificationModal";
const Wallet = ({ account }) => {
  const { user } = useContext(AuthContext);

  const [payments, setpayments] = useState(null);
  const GetPayments = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);

    const res = await axios.post(
      ENDPOINT + "get-transactions.php",
      data,
      SecurityHeaders
    );

    if (res) {
      //  //console.log("withdrawsl "+JSON.stringify(res))
      if (res.data.status === "success") {
        setpayments(res.data.data);
      }
    }
  };

  useEffect(() => {
    SetPage("Wallet");
    GetPayments();
    GetStatistics();
  }, []);

  const [accname, setaccname] = useState(null);

  const [accnumber, setaccnumber] = useState(null);

  const [ifsc, setifsc] = useState(null);
  const [branch, setbranch] = useState(null);
  const [bankname, setbankname] = useState(null);

  const [withdraw, setwithdraw] = useState(false);
  const [confaccnumber, setconfaccnumber] = useState(null);

  const [addbank, setaddbank] = useState(false);

  const [amount, setamount] = useState(0);
  const device = DetectDevice();

  const [statistics, setstatistics] = useState(null);

  const GetStatistics = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);
    const res = await axios.post(
      ENDPOINT + "earning-stats.php",
      data,
      SecurityHeaders
    );

    if (res) {
      setloading(false);
      //  //console.log("Res is "+JSON.stringify(res))
      if (res.data.status === "success") {
        setstatistics(res.data.data);
      }
    }
  };

  const AddNewBank = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);
    data.append("account_name", accname);
    data.append("account_number", accnumber);
    data.append("ifsc", ifsc);
    data.append("branch", branch);
    data.append("bank_name", bankname);

    const res = await axios.post(
      ENDPOINT + "add-bank.php",
      data,
      SecurityHeaders
    );

    if (res) {
      //  //console.log("Res is "+JSON.stringify(res))
      if (res.data.status === "success") {
        setaddbank(false);
      }
    }
  };

  const [editbank, seteditbank] = useState(false);

  const AmountChanged = ({ amount }) => {
    setamount(amount);
    if (amount > statistics.balance) {
    } else {
    }
  };
  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [toastdescription, settoastdescription] = useState(null);
  const [tutorial, settutorial] = useState(false);

  const [loading, setloading] = useState(true);

  const WithdrawAmount = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("session", user.SessionId);
    data.append("amount", amount);
    const res = await axios.post(
      ENDPOINT + "withdraw-amount.php",
      data,
      SecurityHeaders
    );
    if (res) {
      if (res.data.status === "success") {
        setnotification(res.data.msg);
        setwithdraw(false);
        GetStatistics();
        GetPayments()
      }
    } else {
    }
  };
  const [notification, setnotification] = useState(null);
  return (
    <>
      {toast ? (
        <>
          <SimpleNotificationModal
            visibility={toast}
            data={{ title: "none", description: "desc" }}
            handleClose={() => settoast(false)}
          />
        </>
      ) : (
        <></>
      )}
      <Helmet>
        ‍<title>My Wallet - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />
        <meta name="twitter:card" content="" />
        <meta name="twitter:site" content="@skillsuprise" />
        <meta name="twitter:creator" content="@skillsuprise" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
      {loading ? (
        <>
          <div className="container wallet-page min-vh-100 justify-content-between pt-5 d-flex flex-wrap">
            <div className="col-lg-6">
              <div className="w-100 grid lg-grid-3 grid-3">
                <Skeleton variant="rectangular" height={80} />

                <Skeleton variant="rectangular" height={80} />

                <Skeleton variant="rectangular" height={80} />

                <Skeleton variant="rectangular" height={80} />

                <Skeleton variant="rectangular" height={80} />

                <Skeleton variant="rectangular" height={80} />
              </div>

              <div className="w-100 mt-4 grid lg-grid-3 grid-3">
                <Skeleton
                  variant="rectangular"
                  className="rounded-pill"
                  height={40}
                />

                <Skeleton
                  variant="rectangular"
                  className="rounded-pill"
                  height={40}
                />

                <Skeleton
                  variant="rectangular"
                  className="rounded-pill"
                  height={40}
                />
              </div>

              <div className="mt-4">
                <Skeleton
                  variant="rectangular"
                  className="w-100 mb-2"
                  height={60}
                />

                <Skeleton
                  variant="rectangular"
                  className="w-100 mb-2"
                  height={60}
                />

                <Skeleton
                  variant="rectangular"
                  className="w-100 mb-2"
                  height={60}
                />

                <Skeleton
                  variant="rectangular"
                  className="w-100 mb-2"
                  height={60}
                />
              </div>
            </div>

            <div className="col-lg-4">
              <Skeleton variant="rectangular" className="mb-2" height={80} />

              <Skeleton variant="rectangular" className="mb-2" height={80} />

              <Skeleton variant="rectangular" className="mb-2" height={80} />

              <Skeleton variant="rectangular" className="mb-2" height={80} />
            </div>
          </div>
        </>
      ) : (
        <>
          <PaymentsStyled className="pt-2 pt-3 wallet-page w-100 pb-5">
            <div className="toast-container">
              <Toast
                className="bg-warning"
                onClose={() => settoast(false)}
                show={toast}
                delay={3000}
                autohide
              >
                <Toast.Header>
                  <strong className="me-auto">{toasttitle}</strong>
                </Toast.Header>
                <Toast.Body>{toastdescription}</Toast.Body>
              </Toast>
            </div>

            <Modal
              open={tutorial}
              onClose={() => settutorial(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                className="col-lg-4 p-3 col-md-5 col-sm-6 col-11 br-5"
                sx={ModalStyle}
              >
                <div className="modal-header mb-4">
                  <h4>How to use Wallet</h4>
                  <Close className="click" onClick={() => settutorial(false)} />
                </div>

                <video
                  className="w-100"
                  autoPlay
                  controls
                  src="https://skillsuprise.com/data/videos/wallet-tutorial.mp4"
                ></video>
              </Box>
            </Modal>

            <Modal
              open={withdraw}
              onClose={() => setwithdraw(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                className="col-lg-4 p-3 col-md-5 col-sm-6 col-11 br-5"
                sx={ModalStyle}
              >
                <div className="modal-header mb-4">
                  <h4>Withdrawl Request</h4>
                  <Close className="click" onClick={() => setwithdraw(false)} />
                </div>

                <form onSubmit={WithdrawAmount}>
                  <TextField
                    max={
                      statistics && statistics.balance ? statistics.balance : 0
                    }
                    min="10"
                    required
                    type="number"
                    onChange={(e) => {
                      AmountChanged({ amount: e.target.value });
                    }}
                    className="mb-3"
                    fullWidth
                    label="Amount"
                  />

                  <button className="btn btn-primary w-100 ">
                    Withdraw Amount
                  </button>
                </form>
              </Box>
            </Modal>

            <Modal open={addbank} onClose={() => setaddbank(false)}>
              <Box
                className="col-lg-4 p-3 col-md-5 col-sm-6 col-11 br-5"
                sx={ModalStyle}
              >
                <div className="modal-header mb-4">
                  <h4>Add Bank Details</h4>
                  <Close className="click" onClick={() => setaddbank(false)} />
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    AddNewBank();
                  }}
                >
                  <TextField
                    onChange={(e) => setaccname(e.target.value)}
                    className="mb-3"
                    fullWidth
                    type="text"
                    label="Account Name"
                    required
                  />

                  <TextField
                    onChange={(e) => setaccnumber(e.target.value)}
                    className="mb-3"
                    type="number"
                    fullWidth
                    label="Account Number"
                    required
                  />

                  <TextField
                    onChange={(e) => setconfaccnumber(e.target.value)}
                    className="mb-3"
                    type="number"
                    fullWidth
                    label="Confirm Account Number"
                    required
                  />

                  <TextField
                    onChange={(e) => setbankname(e.target.value)}
                    type="text"
                    className="mb-3"
                    fullWidth
                    label="Bank Name"
                    required
                  />

                  <TextField
                    onChange={(e) => setifsc(e.target.value)}
                    type="text"
                    className="mb-3"
                    fullWidth
                    label="IFSC Code"
                    required
                  />

                  <TextField
                    onChange={(e) => setbranch(e.target.value)}
                    type="text"
                    className="mb-3"
                    fullWidth
                    label="Branch"
                    required
                  />
                  <button type="submit" className="btn btn-primary w-100 ">
                    Add Bank Account
                  </button>
                </form>
              </Box>
            </Modal>

            <Modal open={editbank} onClose={() => seteditbank(false)}>
              <Box
                className="col-lg-4 p-3 col-md-5 col-sm-6 col-11 br-5"
                sx={ModalStyle}
              >
                <div className="modal-header mb-4">
                  <h4>Edit Bank Details</h4>
                  <Close
                    className="click"
                    onClick={() => {
                      seteditbank(false);
                    }}
                  />
                </div>
                <TextField className="mb-3" fullWidth label="Account Name" />

                <TextField className="mb-3" fullWidth label="Account Number" />

                <TextField
                  className="mb-3"
                  fullWidth
                  label="Confirm Account Number"
                />

                <TextField className="mb-3" fullWidth label="Bank Name" />

                <TextField className="mb-3" fullWidth label="IFSC Code" />

                <TextField className="mb-3" fullWidth label="Branch" />
                <button className="btn btn-primary w-100 ">
                  Add Bank Account
                </button>
              </Box>
            </Modal>

            <div className="container justify-content-between d-flex flex-wrap min-vh-100">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                {device === "mobile" ? <></> : <></>}

                {statistics ? (
                  <>
                    <div className="grid lg-grid-3  mt-2 mb-5 grid-2 ">
                      <div className="border position-relative rounded-2 earning-box p-2">
                        <h5>
                          ₹ {statistics.earnings ? statistics.earnings : 0}
                        </h5>
                        <p>Total Earnings</p>
                        <div className="wallet-icon">
                          <img className="w-100" src={earningsicon} />
                        </div>
                      </div>

                      <div className="rounded-2 border    position-relative p-2">
                        <h5>₹ {statistics.balance ? statistics.balance : 0}</h5>
                        <p>Current Balance</p>
                        <div className="wallet-icon">
                          <img className="w-100" src={balanceicon} />
                        </div>
                      </div>

                      <div className="rounded-2 border  position-relative p-2">
                        <h5>
                          ₹ {statistics.withdrawls ? statistics.withdrawls : 0}
                        </h5>
                        <p>Withdrawls</p>
                        <div className="wallet-icon">
                          <img className="w-100" src={withdrawlicon} />
                        </div>
                      </div>

                      <div className="rounded-2 border  position-relative p-2">
                        <h5>
                          ₹{" "}
                          {statistics.quiz_earnings
                            ? statistics.quiz_earnings
                            : 0}
                        </h5>
                        <p>Quiz Earnings</p>
                        <div className="wallet-icon">
                          <img className="w-100" src={quizicon} />
                        </div>
                      </div>

                      <div className="rounded-2 border  position-relative p-2">
                        <h5>
                          ₹{" "}
                          {statistics.community_earnings
                            ? statistics.community_earnings
                            : 0}
                        </h5>
                        <p>Comunity Earnings</p>
                        <div className="wallet-icon">
                          <img className="w-100" src={communityicon} />
                        </div>
                      </div>

                      <div className="rounded-2 border  position-relative p-2">
                        <h5>
                          ₹{" "}
                          {statistics.referral_earnings
                            ? statistics.referral_earnings
                            : 0}
                        </h5>
                        <p>Referral Earnings</p>
                        <div className="wallet-icon">
                          <img className="w-100" src={referralicon} />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className=" grid lg-grid-4 md-grid-4 sm-grid-2 grid-2 mb-5">
                  <button
                    onClick={() => {
                      statistics && parseInt(statistics.balance) > 20
                        ? setwithdraw(true)
                        : settoasttitle("Insufficient balance !");
                      settoastdescription(
                        "You donot have sufficient earnings to withdraw"
                      );
                      settoast(true);
                    }}
                    className="btn btn-secondary rounded"
                  >
                    Withdraw
                  </button>
                  <button
                    onClick={() => setaddbank(true)}
                    className="btn  btn-secondary rounded"
                  >
                    Add Bank Details
                  </button>

                  <button
                    onClick={() => {
                      statistics.bank
                        ? seteditbank(true)
                        : settoasttitle("No Bank account added");
                      settoastdescription(
                        "Please add atleast one bank account"
                      );
                      settoast(true);
                    }}
                    className="btn  btn-secondary rounded"
                  >
                    Edit Bank Details
                  </button>

                  <Link to="/contact" className="btn  btn-secondary rounded">
                    Contact Us
                  </Link>
                </div>

                <div>
                  <StyledTransactionPage>
                    <div className="col-12  border border-3 mx-auto  p-4 sm-p-2">
                      <h4 className="mb-4 mt-0">Transaction History</h4>

                      {payments ? (
                        payments[0].status !== "error" ? (
                          payments[0].data !== null ? (
                            payments.map((payment) => (
                              <>
                                <div className="w-100">
                                  <div className="  border border-1 mx-auto p-2 mt-4 d-flex justify-content-between ">
                                    <div className="d-flex">
                                      <div className={  payment.status === "pending"
                                            ? "left-2 border broder-2 p-2 text-center"
                                            : "left border broder-2 p-2 text-center "}>
                                        {payment.status === "pending" ? (
                                          <>
                                            <h4>
                                              {dateFormat(
                                                payment.requested_date,
                                                "dS"
                                              )}
                                            </h4>
                                            <h6>
                                              {dateFormat(
                                                payment.requested_date,
                                                "mmm yyyy"
                                              )}
                                            </h6>
                                          </>
                                        ) : (
                                          <>
                                            <h4>
                                              {dateFormat(
                                                payment.processed_date,
                                                "dS"
                                              )}
                                            </h4>
                                            <h6>
                                              {dateFormat(
                                                payment.processed_date,
                                                "mmm yyyy"
                                              )}
                                            </h6>
                                          </>
                                        )}
                                      </div>
                                      <div className="debit mt-1 ms-3">
                                        <h4>{payment.category}</h4>
                                        <p>Transaction ID : {payment.id}</p>
                                      </div>
                                    </div>
                                    <div>
                                      <h4
                                        className={
                                          payment.status === "pending"
                                            ? "text-warning mt-3"
                                            : "text-success mt-3"
                                        }
                                      >
                                        +{payment.amount}/-
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                          ) : (
                            "error"
                          )
                        ) : (
                          "batch unset"
                        )
                      ) : (
                        <div className="text-center container-fluid bg no-courses p-20">
                          <div className="col-lg-12 justify-center text-center text-dark col-md-12 col-sm-12">
                            <div className=" col-12 mx-auto">
                              <img
                                className="w-50"
                                src="https://skillsuprise.com/resources/images/no-withdrawls.svg"
                                alt=""
                              ></img>

                              <h2 className="mt-5">No Withdrawl History !</h2>
                              <p>
                                Maintain sufficient balance in your account to
                                withdraw the amount.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </StyledTransactionPage>
                </div>

                <div className="d-flex"></div>
              </div>

              {device === "mobile" ? (
                <></>
              ) : (
                <>
                  <div className="col-lg-4">
                    <div className="sm-mt-40 mb-4">
                      <HackingInternshipBanner />
                    </div>
                    <CourseSuggestions />

                    <WorkshopsSuggestions />
                  </div>
                </>
              )}
            </div>
          </PaymentsStyled>
        </>
      )}
    </>
  );
};

export default Wallet;

export const PaymentsStyled = styled.div`
  .plans-section {
    background-image: none;
  }
  li {
    color: #202020;
  }
`;

const StyledTransactionPage = styled.div`
  h3 {
    color: blue;
  }

  .left {
    border-left: 8px solid green !important;
    width: 100px;
  }
  .left-2 {
    border-left: 8px solid #ffc107 !important;
    width: 100px;
  }

  .left-1 {
    border-left: 8px solid red !important;
    width: 100px;
  }

  .main-bg {
    border-radius: 10px;
  }

  .border {
    border-radius: 7px;
  }

  .number {
    background-color: gray;
    padding: 5px;
    border-radius: 45px;
  }

  .icon {
    color: #000080;
  }
`;