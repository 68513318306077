import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import profile from "./assets/BetnomiImages/image.png"

const BetnomiUpComponent = () => {
  return (
    <div className='px-sm-5'>
        <div>
            <div className=' shadow rounded-3 box-container m-sm-4 d-flex flex-wrap '>
            <div className='col-12  col-xl-4 d-flex align-items-stretch'>
            <div className='col-7 p-4'>
               <div className=' bluebackground rounded-3 p-4 text-color h-100'>
               <p className='smallblue mb-1'>My Position</p>
               <h6>23rd</h6>
               <p className='smallblue mb-1'>Wagared</p>
               <div className='d-flex align-items-center'>
                <img className='mb-2  me-2 ' width='15px' height="15px" src="https://cdn-icons-png.flaticon.com/128/5968/5968260.png"></img>
                <h6>01.000221</h6>
                
               </div>
               <p className='smallblue mb-1'>Left to reach TOP</p>
               <div className='d-flex align-items-center '>
                <img className='mb-2 me-2' width='15px' height="15px" src="https://cdn-icons-png.flaticon.com/128/5968/5968260.png"></img>
                <h6>0.000221</h6>
               </div>
               
            </div>

                

            </div>
            <div className='col-5 py-4 pe-4 pe-xl-0'>
                <div className='bluebackground rounded-3 p-4 text-color w-100 h-100'>
                    <div className='d-flex justify-content-center'>
                    <img width='80px' src={profile}></img>

                    </div>
                    <p className='d-flex justify-content-center smallblue pt-2 pb-1'>erma length</p>
                    <ProgressBar className=' mt-2 custum-progress mb-2 ' now={70} />
                    <h6 className='d-flex justify-content-center'>70%</h6>
                </div>

            </div>
            </div>
            <div className='col-12 col-md-5 col-xl-4 d-flex align-items-center'>
                <div className='col-12'>
                <div className='d-flex justify-content-evenly text-color'>
                 
                    <h5>Daily Contest</h5>
                    
                </div>
                <h2 className='d-flex justify-content-center green'>00:00:00</h2>
                <p className='extrasmallblue d-flex justify-content-center'>Hours  :  Minutes  :  Seconds</p>
                <div className='d-flex justify-content-center'>
                <div className='d-flex mt-2 p-1 px-2 justify-content-center align-items-center bluebackground rounded-4 text-color '>
                <img width='15px' height='15px' src='https://cdn-icons-png.flaticon.com/128/5968/5968260.png'></img>
                    <h6 className='ms-2'>0.0111121</h6>
                </div>
                </div>
                </div>
                

            </div>
           <div className='col-12 col-md-7 col-xl-4 p-4'>
           <div className='d-flex justify-content-end align-items-center'>
            <img width="15px" height="20px" src="https://cdn-icons-png.flaticon.com/128/17682/17682043.png"></img>
                <p className='ms-2 smallblue'>Leaderboard</p>
                <p className='ms-1 text-primary'>></p>
            </div>
           <div className='col-12 d-flex  h-100 rank-min-height'>
            <div className='col-4 h-100 overflow-hidden'>
            <div className='h-100 p-1'>
            <div className='h-25'>

</div>
<div className='d-flex justify-content-center'>
    <img  width='60px' src={profile}></img>
</div>
<div className='d-flex justify-content-center'>
<p className='bg-warning rounded rounded-3 px-2 rank'>2nd</p>

</div>
<div className=' w-100 h-100 p-1 bluebackground rounded-3 mt-2'>
    <p className='d-flex justify-content-center text-color'>bronopa..</p>
   <div className='w-100 d-flex justify-content-center'>
   <div className='d-flex align-items-center mt-1 jsutify-content-center'>
    <img width='15px' height='15px' src='https://cdn-icons-png.flaticon.com/128/5968/5968260.png'></img>
    <p className='smallblue ms-1 '>0.00022324</p>

</div>

   </div>

</div>
            </div>
            
            </div>
            <div className='col-4 '>
            <div className='h-100 p-1 overflow-hidden'>
            <div className='d-flex justify-content-center'>
                <img  width='70px' src={profile}></img>
            </div>
            <div className='d-flex justify-content-center'>
            <p className='bg-warning rounded rounded-3 px-2 rank'>1st</p>
            </div>
            <div className=' h-100 p-1 bluebackground rounded-3 mt-2'>
                <p className='d-flex justify-content-center text-color'>jonh</p>
                <div className='w-100 d-flex justify-content-center'>
                <div className='d-flex align-items-center mt-1 jsutify-content-center'>
    <img width='15px' height='15px' src='https://cdn-icons-png.flaticon.com/128/5968/5968260.png'></img>
    <p className='smallblue ms-1 '>0.00022324</p>

</div>


                </div>
            </div>

            </div>
            
            </div>
            <div className='col-4 overflow-hidden'>
                <div className='h-100 p-1 '>
                <div className='h-25'>

</div>
<div className='d-flex justify-content-center pt-4'>
<img  width='50px' src={profile}></img>
</div>
<div className='d-flex justify-content-center'>
<p className='bg-warning rounded rounded-3 px-2 rank'>3rd</p>
</div>
<div className=' w-100 h-100 p-1 bluebackground rounded-3 mt-2'>
    <p className='d-flex justify-content-center text-color'>hidden</p>
   <div className='w-100 d-flex justify-content-center'>
   <div className='d-flex align-items-center mt-1 jsutify-content-center'>
    <img width='15px' height='15px' src='https://cdn-icons-png.flaticon.com/128/5968/5968260.png'></img>
    <p className=' ms-1 smallblue'>0.00022324</p>

</div>

   </div>

</div>

                </div>
            
            </div>
            </div>
           </div>

        </div>
      
    </div>
    </div>
  )
}

export default BetnomiUpComponent
