import React, { useEffect, useState } from 'react';
import '../../assets/css/support-team.css';
import { AddIcCall, Email, WhatsApp } from '@material-ui/icons';
import DuoIcon from '@mui/icons-material/Duo';
import axios from 'axios';
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';

const SupportTeam = () => {
    const [team, setTeam] = useState([]); 
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [id, setId] = useState(null); 


    const fetchData = async () => {
        try {
            const data = new FormData();
            if (id) {
                data.append('id', id); 
            }
            const res = await axios.post(ENDPOINT+"support-team.php", data, SecurityHeaders);

            if (res) {
               if(res.data){
                setTeam(res.data);
               }
               
            } else {
                
                setError('Invalid response format. Expected an array of team members.');
            }
        } catch (err) {
         
            setError(`Failed to fetch support team data. Error: ${err.message || 'Unknown error'}`);
            console.error("Error fetching support team data:", err);
        } finally {
           
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]); 

    if (loading) return <p>Loading...</p>; 
    if (error) return <p>{error}</p>; 
    return (
        <div className='container'>
            <h2 className="text-primary text-center m-3">Our Support Team</h2>

            <div className='d-flex flex-wrap mb-4'>
                
                {team && team.data.length > 0 ? (
                    team.data.map((member, index) => (
                        <div key={index} className='col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='d-flex p-2 bg-warning-subtle m-2 profile'>
                                <div className='col-4'>
                                    <img
                                        src={member.profile_pic} 
                                        height="150px"
                                        className='profile img_res rounded-2'
                                        alt={member.name || 'Team Member'}
                                    />
                                </div>
                                <div className="col-8 align-content-center ms-2">
                                    <h5>{member.first_name}</h5>
                                    <p><b>{member.designation}</b></p>
                                    <div className='mt-2'>
                                        <div className='d-flex mt-1 mb-3'>
                                            <button className='text-black border-0 text-center pt-0 pb-0 icon'>
                                                <AddIcCall className='blue' fontSize="inherit" />
                                            </button>
                                            <p className='ms-2'>
                                                <a href={`tel:${member.phone}`} className='text-black text-decoration-none'>
                                                    {member.phone}
                                                </a>
                                            </p>
                                        </div>
                                        <div className='d-flex flex-nowrap'>
                                            <button className='text-white border rounded rounded-2 bg-none border-none pt-0 pb-0'>
                                                <Email className='blue' />
                                            </button>
                                            <button className='text-white border rounded rounded-2 bg-wapp border-none ms-2 text-center pt-0 pb-0'>
                                                <WhatsApp className='green' />
                                            </button>
                                            <button className='bg-primary rounded rounded-4 border text-white border-none ms-2'>
                                                <DuoIcon /> Meet
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No team members found.</p> 
                )}
            </div>
        </div>
    );
};

export default SupportTeam;
