import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Image1 from '../account/assets/BetnomiImages/image1.jpg';
import Image2 from '../account/assets/BetnomiImages/image2.jpg';
import Image3 from '../account/assets/BetnomiImages/image3.jpg';

const Betnomidown = () => {
  return (
    <div className='px-sm-5'>
      <div className='col-12 text-light d-flex overflow p-3 h-100'>
        <div className=' col-12 col-lg-6 col-xl-4 col-md-8 mt-3'>
          <div  className='col-12 first h-75  position-relative'>
          <div className='para position-absolute'>
              <div className='d-flex align-items-center mt-4 ms-2'>
                <div className='bg-warning heading-bullet'></div>
              <h4 className=' '>Betnomi <span className='text-danger'>VIP</span> </h4>
              </div>
              <div className='col-8 ms-5'>
              <p className='text-white'>The Experience you deserve</p>
              </div>
              <button className='violet-button mt-4 border rounded-3'>Learn More</button>
            </div>
            <img className='col-12 h-100   rounded-3 img1 shadow'src={Image1} alt='img1'></img>
            
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-4 col-md-8 mt-3 ms-1 '>
          <div  className='col-12 first '>
          <div className='para position-absolute'>
          <div className='d-flex align-items-center mt-4 ms-2'>
                <div className='bg-warning heading-bullet'></div>
              <h4 className=' '>Welcome Bonus</h4>
              </div>
              <div className='col-10 ms-5'>
              <p className='mb-0 text-white'>Start to win with Betinomi</p>
              <p className='text-white'>Get up to 150% bonus</p>
              </div>
              <button className='green-button mt-4 border rounded-3'>Learn More</button>
            </div>
            <img className='col-12   rounded-3 img1  shadow'src={Image2} alt='img1'></img>
            
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-4 col-md-8 mt-3 ms-1'>
          <div  className='col-12 first d-flex h-75 position-relative'>
          <div className='para position-absolute ms-0'>
          <div className='d-flex align-items-center mt-4 ms-2  '>
                <div className='bg-warning heading-bullet'></div>
              <h4 className=''>SportsBook </h4>
              </div>
              <div className='col-8 ms-5'>
              <p>Over 150 leagues, countries and matches</p>
              
              </div>
              <button className='blue-button mt-4 border rounded-3'>Learn More</button>
              </div>
            <img className='col-12 shadow-sm rounded-3 img1'src={Image3} alt='img1'></img>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Betnomidown