import React from "react";
import CourseLeaderboard from "./CourseLeaderboard";
import { Card, Badge, Row, Col } from 'react-bootstrap';
import { FaTrophy } from 'react-icons/fa'; 
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import GetCourseBatch from "../../../actions/GetCourseBatch";
import Localbase from "localbase";
import { AuthContext, AuthContextProvider } from "../../../context/AuthContext";
import { Avatar } from "@mui/material";
import "../../../assets/css/leaderboard.css";
import winner from "../../../assets/images/winner.png";
import bronze from "../../../assets/images/bronze-badge.png";
import silver from "../../../assets/images/silver-badge 3.png";
import Test from "../../courses/Test";
import ConnectionItemSidebarSuggestion from "../../connections/ConnectionItemSidebarSuggestion";
import CommunitySuggestions from "../../community/CommunitySuggestions";
import GettingStartedBatchprogress from "./GettingStartedBatchprogress";

const CourseLeaderboardPage = () => {
  const params = useParams();
  const course_url = params.course_url;
  const batch_id = params.batch_id;

  let db = new Localbase("db");
  const { user } = useContext(AuthContext);
  const [batch, setbatch] = useState(null);
  const GetBatch = async () => {
    const res = await GetCourseBatch({
      payload: {
        course_url: course_url,
        limit: 100,
        batch_id: batch_id,
        user: user.SessionId,
      },
    });
    if (res) {
      if (res.status === "success" && res.team !== null) {
        setbatch(res.team);

        db.collection("paths").add(
          {
            batch: res.team,
          },
          "batch",
        );
      }
      //  //console.log("batch "+JSON.stringify(res))
    }
  };

  console.log(batch ? batch[0] : null);
  useEffect(() => {
    db.collection("paths")
      .doc("batch")
      .get()
      .then((batch) => {
        setbatch(batch.batch);
      })
      .catch((error) => {});

    GetBatch();
  }, []);

  return (
    <div className="w-100">
      <div className=" p-0 pt-5">
        <div className="d-flex justify-content-center flex-wrap">
          {batch ? (
             <Card className='cards' style={{ width:'380px', borderRadius: '10px', margin : '5px', padding:'5px'}}>
             <Card.Body>
               <Row className="align-items-center">
                 <Col xs={3} sm={2} md={3}>
                   <div>
                     <span style={{ color: '#fff', fontWeight: 'bold' }}><Avatar src={batch[0].profile_pic}></Avatar></span>
                   </div>
                 </Col>
                 <Col xs={6} sm={7} md={6}>
                   <Card.Title className="mb-0" style={{ fontSize: '15px' }}>{batch[0].first_name} {batch[0].last_name}</Card.Title>
                   {/* <Badge bg="primary" text="dark" style={{ fontSize: '12.8px' }}>Regular Guy</Badge> */}
                 </Col>
                 <Col xs={3} sm={3} md={3} className="text-end">
                   <FaTrophy color="#f5a623" size={24} />
                 </Col>
               </Row>
               <hr style={{ backgroundColor: '#444', borderWidth: '1px' }} />
               <Row>
                 <Col xs={4} sm={4} md={4} className="text-center">
                   <div style={{ fontSize: '17.6px', fontWeight: 'bold' }}> {batch[0].points}</div>
                   <div style={{ fontSize: '12.8px', color: '#aaa' }}>Points</div>
                   {/*This is the div For The Dashed Line Showing the progress */}
                   <div
                     style={{
                       borderTop: '2px dashed ',
                       width: '20%',                   
                       margin: '0 auto',    
                       color: 'red'
                     }}
                   ></div>{/* The line div Ends */}
                 </Col>
                 <Col xs={4} sm={4} md={4} className="text-right">
                   <div style={{ fontSize: '17.6px', fontWeight: 'bold' }}> {batch[0].attendance}%</div>
                   <div style={{ fontSize: '12.8px', color: '#198754'}}>Attendance</div>
                   <div
                     style={{
                       borderTop: '2px dashed ',
                       width: '96%', 
                       marginTop : '5px', 
                       color :'#198754',                  
                       margin: '0 auto', 
                     }}
                   ></div>
                 </Col>
                 <Col xs={4} sm={4} md={4} className="text-center">
                   <div style={{ fontSize: '17.6px', fontWeight: 'bold', color: '#1e90ff' }}>"{batch[0].rank}"</div>
                   <div style={{ fontSize: '12.8px', color: '#aaa' }}>Rank</div>
                   <div
                     style={{
                       borderTop: '2px dashed ',
                       width: '20%', 
                       marginTop : '5px', 
                       color :'#1e90ff',                  
                       margin: '0 auto',   
                     }}
                   ></div>
                 </Col>
               </Row>
             </Card.Body>
           </Card>
          ) : null}

          {batch ? (
            <Card className='cards' style={{ width: '380px', borderRadius: '10px',margin : '5px' }}>
            <Card.Body>
              <Row className="align-items-center">
                <Col xs={3} sm={2} md={3}>
                  <div>
                    <span style={{ color: '#fff', fontWeight: 'bold' }}><Avatar src={batch[1].profile_pic}></Avatar></span>
                  </div>
                </Col>
                <Col xs={6} sm={7} md={6}>
                  <Card.Title className="mb-0" style={{ fontSize: '15px' }}>{batch[1].first_name} {batch[1].last_name}</Card.Title>
                  {/* <Badge bg="primary" text="dark" style={{ fontSize: '12.8px' }}>Software Developer</Badge> */}
                </Col>
                <Col xs={3} sm={3} md={3} className="text-end">
                  <FaTrophy color="white" size={24} />
                </Col>
              </Row>
              <hr style={{ backgroundColor: '#444', borderWidth: '1px' }} />
              <Row>
                <Col xs={4} sm={4} md={4} className="text-center">
                  <div style={{ fontSize: '17.6px', fontWeight: 'bold' }}> {batch[1].points}</div>
                  <div style={{ fontSize: '12.8px', color: '#aaa' }}>Points</div>
                  <div
                    style={{
                      borderTop: '2px dashed ',
                      width: '20%',                   
                      margin: '0 auto',    
                      color: 'red'
                    }}
                  ></div>
                </Col>
                <Col xs={4} sm={4} md={4} className="text-right">
                  <div style={{ fontSize: '17.6px', fontWeight: 'bold' }}> {batch[1].attendance}%</div>
                  <div style={{ fontSize: '12.8px', color: '#198754'}}>Attendance</div>
                  <div
                    style={{
                      borderTop: '2px dashed ',
                      width: '96%', 
                      marginTop : '5px', 
                      color :'#198754',                  
                      margin: '0 auto', 
                    }}
                  ></div>
                </Col>
                <Col xs={4} sm={4} md={4} className="text-center">
                  <div style={{ fontSize: '17.6px', fontWeight: 'bold', color: '#1e90ff' }}>"{batch[1].rank}"</div>
                  <div style={{ fontSize: '12.8px', color: '#aaa' }}>Rank</div>
                  <div
                    style={{
                      borderTop: '2px dashed ',
                      width: '20%', 
                      color :'#1e90ff',                  
                      margin: '0 auto',   
                    }}
                  ></div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          ) : null}

          {batch ? (
          <Card className='cards' style={{ width: '380px',borderRadius: '10px',margin : '5px' }}>
          <Card.Body>
            <Row className="align-items-center">
              <Col xs={3} sm={2} md={3}>
                <div>
                  <span style={{ color: '#fff', fontWeight: 'bold' }}><Avatar src={batch[2].profile_pic}></Avatar></span>
                </div>
              </Col>
              <Col xs={6} sm={7} md={6}>
                <Card.Title className="mb-0" style={{ fontSize: '15px' }}>{batch[2].first_name} {batch[2].last_name}</Card.Title>
                {/* <Badge bg="primary" text="dark" style={{ fontSize: '12.8px' }}>Miss Wistm</Badge> */}
              </Col>
              <Col xs={3} sm={3} md={3} className="text-end">
                <FaTrophy color="CD7F32" size={24} />
              </Col>
            </Row>
            <hr style={{ backgroundColor: '#444', borderWidth: '1px' }} />
            <Row>
              <Col xs={4} sm={4} md={4} className="text-center">
                <div style={{ fontSize: '17.6px', fontWeight: 'bold' }}> {batch[2].points}</div>
                <div style={{ fontSize: '12.8px', color: '#aaa' }}>Points</div>
                <div
                  style={{
                    borderTop: '2px dashed ',
                    width: '20%',                   
                    margin: '0 auto',    
                    color: 'red'
                  }}
                ></div>
              </Col>
              <Col xs={4} sm={4} md={4} className="text-right">
                <div style={{ fontSize: '17.6px', fontWeight: 'bold' }}> {batch[2].attendance}%</div>
                <div style={{ fontSize: '12.8px', color: '#198754'}}>Attendance</div>
                <div
                  style={{
                    borderTop: '2px dashed ',
                    width: '96%', 
                    marginTop : '5px', 
                    color :'#198754',                  
                    margin: '0 auto', 
                  }}
                ></div>
              </Col>
              <Col xs={4} sm={4} md={4} className="text-center">
                <div style={{ fontSize: '17.6px', fontWeight: 'bold', color: '#1e90ff' }}>"{batch[2].rank}"</div>
                <div style={{ fontSize: '12.8px', color: '#aaa' }}>Rank</div>
                <div
                  style={{
                    borderTop: '2px dashed ',
                    width: '20%', 
                    marginTop : '5px', 
                    color :'#1e90ff',                  
                    margin: '0 auto',   
                  }}
                ></div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
          ) : null}
        </div>
      </div>
      <div className="container col-12">
        <div className="d-flex flex-wrap">
          <div className="col-12">
            <CourseLeaderboard />
          </div>

          {/* <div className="col-lg-5 col-md-12 col-sm-12 col-12 px-sm-2 px-0">
            <div className=" bg-white rounded border border-1 my-5">
              <div className="p-md-4 p-sm-4 p-1">
                <GettingStartedBatchprogress />
                <ol>
                  <li>
                    Make sure you submit assignment on or before deadline to
                    gain more points
                  </li>
                  <li>Also reach your milestones to get more points</li>
                </ol>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CourseLeaderboardPage;
