
import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const ChatPopIUp = () => {
    return (
            <div className="fixed-chat-popup w-100 justify-content-end d-flex">
                <a target="_blank" href={"https://api.whatsapp.com/send/?phone=919490174876&text=Hello&type=phone_number&app_absent=0"} >
                    <button className="home d-flex p-2 px-2 px-md-3 p-md-2 m-0 rounded rounded-pill border-0 ">

                        <div className="d-flex text-success pe-0 pe-md-1">
                            <WhatsAppIcon className="me-0 me-md-2" />

                            <h6 className="text-light d-none d-md-block m-0 p-0">Chat with us</h6>

                        </div>

                    </button>
                </a>
            </div>
    )
}

export default ChatPopIUp