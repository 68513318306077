import React, {useState} from "react";
import Rating from "@mui/material/Rating";
import axios from "axios";
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SimpleNotificationModal from "../../components/modals/SimpleNotificationModal";



const SupportFeedback = () => {
  const params = useParams()


  const id = params.id
const [fullName, setFullName] = useState(null);
const [email, setEmail] = useState(null);
const [phone, setPhone] = useState(null);
const [message, setMessage] = useState(null);
const [rating, setRating] = useState(0);
const [checked, setChecked] = useState(false);

const feedbackForm = async (event) => {

  const data = new FormData();

  data.append("review", message);
  data.append("rating", rating);

  data.append("id",id)

  try {
    const res = await axios.post(
      ENDPOINT + `submit-customer-support-review.php`,
      data,
      SecurityHeaders,
    );
if(res){
  if (res.data&&res.data.status==="success") {
    setdata(res.data)
   
    setnotification(true)
 
  } else {
    settitle("Something went wrong. Try again later.")
    setnotification(true)
  }
}
 
  } catch (error) {
    settitle("Something went wrong. Try again later.")
    setnotification(true)
  }
};


const [data,setdata] = useState({title:"",description:""})
const [notification,setnotification] = useState(false)

const [title,settitle] = useState(false)

  return (
    <div>
  <SimpleNotificationModal visibility={notification} handleClose={()=>setnotification(false)} data={data?data:null} />
      <div className="container d-flex justify-content-center">
        <div className="col-lg-5 col-md-8 col-sm-10 col-12 border rounded p-3 my-5">



          <h2 className="text-center mb-4 mt-2">Feedback Form</h2>

          <form onSubmit={(e)=>{e.preventDefault();feedbackForm();}}>
        

        

            <div className="mb-3">
              <label className="form-label">Feedback</label>
              <textarea
                className="form-control min-vh-20"
                name="Enter your feedback"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Please describe your feedback..."
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Rating *</label>
              <div className="rating">
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
              </div>
            </div>

        
            <button
              type="submit"
            
              className="btn btn-primary w-100"
            >
              Submit Feedback
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SupportFeedback;
