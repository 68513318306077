import axios from "axios";
import React, { useEffect, useState } from "react";
import ENDPOINT from "../../data/ENDPOINT";
import SecurityHeaders from "../../data/SecurityHeaders";
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import ModalStyle from "../../data/ModalStyle";
import SetPage from "../../data/SetPage";
import Localbase from "localbase";
import MockTests from "../../components/skeletons/MockTests";
import SearchIcon from "@mui/icons-material/Search";
import Rating from '@mui/material/Rating';
const MockTestCategories = ({ account }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    Getofflinedata();
  }, [window.location]);

  let db = new Localbase("db");
  const [categories, setcategories] = useState(null);

  const [search, setSearch] = useState("");

  const GetQuizCategories = async (payload) => {
    setloading(true);
    const data = new FormData();

    const res = await axios.post(
      ENDPOINT + "mock-test-categories.php",
      data,
      SecurityHeaders
    );

    if (res) {
      setloading(false);
      if (res.data.status === "success") {
        setcategories(res.data.data);
        setResponse(res.data.data);
        setInititalData(res.data.data);
        db.collection("paths").add(
          {
            mock_test_categories: res.data.data,
          },
          "mock_test_categories"
        );
      }
    }
  };
  const Getofflinedata = () => {
    db.collection("paths")
      .doc("mock_test_categories")
      .get()
      .then((data) => {
        setcategories(data.mock_test_categories);
        setResponse(data.mock_test_categories)
        setloading(false);
      })
      .catch((error) => { });
  }

  const [query, setquery] = useState(null)

  const SearchMocktests = (e) => {

    e.preventDefault()

  }

  useEffect(() => {
    GetQuizCategories();
    SetPage("Mock Tests");
  }, []);

  useEffect(() => {
    if (account) {
      if (account.purchases && account.purchases.length > 0) {
        setaccess(true);
      } else {
        setaccess(false);
      }
    }
  }, [account]);

  const [access, setaccess] = useState(false);
  const [modal, setmodal] = useState(false);

  const ViewQuiz = () => {
    if (access) {
    } else {
      setmodal(true);
    }
  };
  const [loading, setloading] = useState(true);

  const [initialData, setInititalData] = useState(null);

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return item.title.toLowerCase().includes(term);
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  const [value, setValue] = useState("2");



  return (
    <>
      <Modal
        open={modal}
        onClose={() => {
          setmodal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="col-lg-4 col-md-5 col-sm-6 col-11 p-2" sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Student Access Only !
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Login into your account to access this section
            <div className="d-flex justify-content-between mt-4">
              <button
                onClick={() => setmodal(false)}
                className="btn btn-sm  btn-outline-danger rounded-pill"
              >
                Cancel
              </button>
              <Link
                to="/login"
                className="btn btn-sm  btn-primary rounded-pill"
              >
                Login
              </Link>
            </div>
          </Typography>
        </Box>
      </Modal>

      <div className="d-flex sm-col-reverse flex-wrap align-items-center mock-test-bg text-light w-100 min-vh-30">
        <div className="container justify-content-center  d-flex flex-wrap">
          <div className="col-lg-8 text-center pt-2 pt-md-0 ps-md-3 ps-0 col-sm-12 col-12">
            <h2>Expertly Crafted Mock Tests to Crack Your Dream Job</h2>

            <Paper
              component="form"
              onSubmit={(e) => SearchMocktests(e)}
              className="mt-3 col-lg-8 col-md-9 col-12 mb-4 mx-auto"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            >

              <InputBase
                onChange={handleSearch}
                value={query}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Mock Tests"
                inputProps={{ 'aria-label': 'search Mocktets' }}
              />
              <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                {query ?
                  <>
                    {/* <CloseIcon onClick={() => setquery(null)} /> */}
                  </> : <SearchIcon />}
              </IconButton>

            </Paper>





            {/* <div className="d-flex justify-content-end mt-5 mb-3 container">
              <div className="d-flex p-1 border border-1 align-items-center  col-12 col-sm-10 col-md-4 col-lg- ">
                <SearchIcon className="ri-search-line  text-secondary" />
                <input
                  onChange={handleSearch}
                  type="text"
                  className="border border-0"
                  placeholder="Search"
                />
              </div>
            </div> */}
          </div>
        </div>

        {/* <div className="col-lg-5 ">
          <img
            src="https://skillsuprise.com/resources/images/prepare-mock.png"
            className="w-100"
          />
        </div> */}
      </div>

      <div className="min-vh-100  interview-praparation pb-5">


        <div className=" container mt-4 d-flex  flex-wrap">
          {response ? (
            <>
              {response.map((item) => (
                <>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 p-0 p-md-3 p-sm-2 ">
                    <div className=" quiz-category  p-0">
                      <img
                        className="w-100 mb-3 br-4 prep-image-category"
                        src={item.image}
                      ></img>
                      <div className="p-2">
                        <h5>{item.title}</h5>

                        <div className=" text-secondary ">
                          <p className="sm-13">
                            Total quizzes : {item.total_quizzes}
                          </p>
                          <p className="sm-13 d-flex align-items-center">
                            Difficulty Level : {item.level}
                            {/* <Rating className="" name="read-only" value= precision={0.5} readOnly /> */}
                
                          </p>
                        </div>
                        {/* <hr /> */}
                        <div className="d-flex  justify-content-between mt-3">
                          <span></span>
                          {access ? (
                            <>
                              <Link
                                to={"/test-topics/mock-test/" + item.course_url}
                                className="btn btn-primary w-100"
                              >
                                View Tests
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link
                                to={"/test-topics/mock-test/" + item.course_url}
                                className="btn btn-primary w-100">
                                View Tests
                              </Link>
                            </>
                          )}
                        </div>
                      </div>

                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              {loading ? (
                <>
                  <div className="mt-5 w-100 text-center">
                    <MockTests />
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MockTestCategories;