import React from 'react'
import BetnomiUpComponent from './BetnomiUpComponent'
import Betnomidown from './Betnomidown'

const BetnomiPage = () => {
  return (
    <div>
        <BetnomiUpComponent/>
        <Betnomidown/>
        
      
    </div>
  )
}

export default BetnomiPage
