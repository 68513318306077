import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import rewardduplicate from '../../assets/reward-duplicate.png'
import ENDPOINT from '../../data/ENDPOINT';
import { AuthContext } from '../../context/AuthContext';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Lock from '@mui/icons-material/Lock';
import Localbase from 'localbase'
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import DoneIcon from '@mui/icons-material/Done';
import RedeemIcon from '@mui/icons-material/Redeem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import '../../assets/css/refer.css'
import '../../assets/css/refer.css'
import ReferralProgram from './ReferralProgram';

const ScratchCards = () => {



    return (
        <Styled>
            <>
<>


</>



                <div className="container-fluid p-0 p-md-5 col-lg-12">
                    <div className="d-flex flex-wrap justify-content-between">
                        {/* Online Course Box */}
                        <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 p-3">
                            <div className="border card bg-white rounded-4 box-shadow hover-scale p-3 hover-purple">
                                <h2 className="text-center mt-2">Online Course</h2>
                                <p className="text-center mt">Everything you need to learn a new skill from your home</p>
                                <div className="mt-3">
                                    <div className="d-flex align-items-center">
                                    👨‍💻 <p className="ms-1">Daily online class</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    ▶️<p className="ms-1">Daily class recording</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    🕖<p className="ms-1">24/7 Mentor support</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    💻<p className="ms-1">Premium Notes & Softwares</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                  👩🏻‍💼<p className="ms-1">Placement trainings</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    💯<p className="ms-1">100% Placement assistance</p>
                                    </div>
                                </div>
                                <div className="bg1 mt-3 ps-3">
                                    <div className="d-flex mb-1">
                                        <p className="m-0">They Get</p>
                                        <div className="border border-dark rounded-3 ms-2 px-2">₹ 150 OFF</div>
                                    </div>
                              
                                </div>
                                <div className="d-flex align-items-center text-success bg2 py-2 ps-2 mt-2 rounded">
                                    🎁<p className="ms-1 sm-18 m-0">Your commission</p>
                                    <h5 className="ms-3 sm-16 m-0 p-0">₹ 150</h5>
                                </div>
                                <div className="d-flex mt-4 mb-2">
                                    <button className="border rounded bg-white p-2 copyb">📋</button>
                                    <button className="refer-button border  ms-2 w-100 text-center  rounded pt-2 copyb">
                                        <h6 className='button'> Refer to Friend</h6>
                                    </button>
                                </div>
                            </div>
                        </div>

                     

                        {/* Offline Course Box */}
                        <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 p-3">
                            <div className="border card bg-white rounded-4 box-shadow hover-scale p-3 hover-purple">
                                <h2 className="text-center mt-3">Offline Course</h2>
                                <p className="text-center mt">Everything you need to master skills with us</p>
                                <div className="mt-3">
                                    <div className="d-flex align-items-center">
                                    👨‍💻 <p className="ms-1">Daily online class</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    ▶️<p className="ms-1">Daily class recording</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    🕖<p className="ms-1">24/7 Mentor support</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    💻<p className="ms-1">Premium Notes & Softwares</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                  👩🏻‍💼<p className="ms-1">Placement trainings</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    💯<p className="ms-1">100% Placement assistance</p>
                                    </div>
                                </div>
                                <div className="bg1 mt-3 ps-3">
                                    <div className="d-flex mb-2">
                                        <p className="m-0">They Get</p>
                                        <div className="border border-dark rounded-3 ms-2 px-2"> ₹ 250 OFF</div>
                                    </div>
                                 
                                </div>
                                <div className="d-flex align-items-center text-success bg2 py-2 ps-2 mt-3 rounded ">
                                    🎁<p className="ms-1 sm-18 m-0">Your commission</p>
                                    <h5 className="ms-3 sm-16 m-0 p-0">₹ 250</h5>
                                </div>
                                <div className="d-flex mt-4 mb-2">
                                    <button className="border rounded bg-white p-2 copyb">📋</button>
                                    <button className="refer-button border ms-2 w-100 text-center  rounded pt-2 copyb">
                                        <h6 className='button'> Refer to Friend</h6>
                                    </button>
                                </div>
                            </div>
                        </div>



                           {/* Specialization Course Box */}
                           <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 p-3">
                            <div className="border card bg-white rounded-4 box-shadow hover-scale p-3 hover-purple">
                                <h2 className="text-center mt-3">Specialization Course</h2>
                                <p className="text-center mt">Everything you need to land your dream job</p>
                                <div className="mt-3">
                                    <div className="d-flex mt-1 align-items-center">
                                    👨‍💻 <p className="ms-1">Daily online class</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    ▶️<p className="ms-1">Daily class recording</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    🕖<p className="ms-1">24/7 Mentor support</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    💻<p className="ms-1">Premium Notes & Softwares</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                  👩🏻‍💼<p className="ms-1">Placement trainings</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    💯<p className="ms-1">100% Placement assistance</p>
                                    </div>
                                </div>
                                <div className="bg1 mt-3 ps-3">
                                    <div className="d-flex mb-2">
                                        <p className="m-0">They Get</p>
                                        <div className="border border-dark rounded-3 ms-2 px-2"> ₹ 500 OFF</div>
                                    </div>
                              
                                </div>
                                <div className="d-flex align-items-center text-success bg2 py-2 ps-2 mt-3 rounded ">
                                    🎁<p className="ms-1 sm-18 m-0">Your commission</p>
                                    <h5 className="ms-3 sm-16 m-0 p-0">₹ 500</h5>
                                </div>
                                <div className="d-flex mt-4 mb-2">
                                    <button className="border rounded bg-white p-2 copyb">📋</button>
                                    <button className="refer-button border ms-2 w-100 text-center  rounded pt-2 copyb">
                                        <h6 className='button'> Refer to Friend</h6>
                                   
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>









            </>

        </Styled>



    )
}
export default ScratchCards
const Styled = styled.div`
.bg{
   background-color:#c5cde926}
.bg1{ 
   background-color:#fafbff}

 .bg2{ 
   background-color:#D6EAF8}
.text{ 
   color:#2f1c6a}
.refer{
  color:#673de6} 
.bg3{
   background-color:#673de6}  
.bd{
  border: 1px solid #673de6 !important;
}
.mt28{
   margin-top:-28px;}  

`
