import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetCourses from '../../actions/GetCourses'
import GetPlacedStudents from '../../actions/GetPlacedStudents'
import GetSacVideos from '../../actions/GetSacVideos'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { ChevronLeft } from '@mui/icons-material'
import AnimatePage from '../../actions/animations/AnimatePage'
import Localbase from 'localbase'
const SuccessStories = ({mode}) => {
  
  const db = new Localbase('db')


  var scrollAmount ;

  
   const buttonclick = ()=> {
        var container = document.getElementById('content-container');
        sideScroll(container,'right',15,300,10);
    };
    
    
    const back = ()=> {
        var container = document.getElementById('content-container');
        sideScroll(container,'left',15,300,10);
    };
    
    function sideScroll(element,direction,speed,distance,step){
        scrollAmount = 0;
        var slideTimer = setInterval(function(){
            if(direction === 'left'){
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if(scrollAmount >= distance){
                window.clearInterval(slideTimer);
            }
        }, speed);
    }
    

const [loading,setloading] = useState(false)


useEffect (()=>{
AnimatePage()
NewVideos()
},[])
    const NewVideos = async()=>{
      setloading(true)
      
      
      
          const res = await GetPlacedStudents(
            query?
            {payload:{limit:limit,query:query}}
            :
            {payload:{limit:limit}}
          )
          
          if(res){
            setloading(false)
          }
          if(res.status==="success"){
           
            setvideos(res.data)
         
            db.collection('paths').add({videos : res.data},"videos")

            if(res.videos){
      
              if(res.videos.length===res.total_videos){
                setnomore(true)
              }
              settotalvideos(res.videos.length)
            }
          
           
            setsuggestions(res.suggestions)

      
      
      AnimatePage()
           
          }



        }

        const [query,setquery] = useState(null)
const [limit,setlimit] = useState(12)
const [totalvideos,settotalvideos] = useState(null)
const [nomore,setnomore] = useState(false)
        const [videos,setvideos] = useState(null)
        const [suggestions,setsuggestions] = useState(null)


        const Getofflinedata = () => {
          db.collection('paths').doc('videos').get().then((data)=>{
            setvideos(data.videos)
            
          }).catch((error)=>{ })
        }
        useEffect(()=>{
          Getofflinedata()
        },[window.location])
    
  
        return (
    <div className="container sm-p-15 d-flex flex-wrap">

      <div className="d-flex w-100 w-100 justify-content-between">



  <div className="d-flex mb-2 w-100 align-items-center justify-content-between">
  <h3 className="left-blue-border mn-0 pb-0">Latest Placements</h3>

  <div className="d-flex align-items-center">
    
   <Link to="/reviews" className="sm-10 btn btn-outline-primary rounded-pill">View all</Link>

  <div class="slider-buttons ms-3 mr-15">

    
    <ChevronLeft  id="slideBack" className="me-2"  onClick={back} alt="" type="button" src={"previewsvg"}/>
    <ChevronRight  id="slide" onClick={buttonclick} type="button"  src={"nextsvg"} alt=""/>
   

</div>
  </div>
  
    </div>

  


      
      </div>
     
<div id="content-container" className="d-flex animate w-100 overflow-x sac-scroll mt-3 pb-3">

{videos?
<>
{videos.map((item,index)=>(
<>

<div  className={" alumni-item shadow fade-in-bottom delay0"+(3+(index+1))+"s"}>
<h5 className="package">{item.package+" LPA "}</h5>
<img className="" src={item.image}></img>
<div className="p-2 text-light">

  <h5>{item.full_name}</h5>

  <p className="text-light">{item.role}</p>
</div>

</div>

</>

))}

</>:null}




</div>


    </div>
  )
}

export default SuccessStories