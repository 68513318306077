import Chip from '@mui/material/Chip';
import React, { useEffect } from 'react';
import ScratchCards from './ScratchCards';
import { useContext, useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import { AuthContext } from '../../context/AuthContext'
import Toast from 'react-bootstrap/Toast'
import LoadingTemplate from '../../templates/LoadingTemplate'
import img1 from '../../assets/images/img1.png'
import img2 from '../../assets/images/IMG2.png'
import img3 from '../../assets/images/IMG3.png'


import {
     EmailIcon,
     EmailShareButton,
     FacebookIcon,
     FacebookShareButton,


     LinkedinIcon,

     LinkedinShareButton,


     TelegramIcon,


     TelegramShareButton,

     TwitterIcon,

     TwitterShareButton,

     WhatsappIcon,

     WhatsappShareButton,

} from "react-share";
import Localbase from 'localbase'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios'
import SetPage from '../../data/SetPage';
import { AvatarGroup } from '@mui/material';
import { Avatar } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import FetchFingerprint from '../../actions/FetchFingerprint';
import UnregisteredReferralPage from './UnregisteredReferralPage';
// import background from '../referral-program/assets/background.png'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const ReferralProgram = ({ props }) => {

     // firts boxs code start


     const [signups, setSignups] = useState(0);
     const [coupons, setCoupons] = useState(0);
     const [money, setMoney] = useState(0);
     const handleShowModal1 = () => setShowModal1(true);
     const handleCloseModal1 = () => setShowModal1(false);

     const handleShowModal2 = () => setShowModal2(true);
     const handleCloseModal2 = () => setShowModal2(false);

     const handleShowModal3 = () => setShowModal3(true);
     const handleCloseModal3 = () => setShowModal3(false);


     useEffect(() => {
          const interval = setInterval(() => {
               setSignups((prev) => (prev < 52 ? prev + 1 : prev));
               setCoupons((prev) => (prev < 31 ? prev + 1 : prev));
               setMoney((prev) => (prev < 9870 ? prev + 100 : prev));
          }, 50);

          return () => clearInterval(interval);
     }, []);

     // ends


     const { user } = useContext(AuthContext)

     const db = new Localbase('db');

     const [toast, settoast] = useState(false);
     const [message, setmessage] = useState(null)
     useEffect(() => {

          SetPage("Refer & Earn")

          GetReferrals();

     }, [])
     useEffect(() => {
          window.scrollTo(0, 0)
     }, [])
     const [refdata, setrefdata] = useState(null)


     const GetReferrals = async () => {


          const formData = new FormData();
          formData.append('session', user.SessionId)
          formData.append("fp", FetchFingerprint());

          const res = await axios.post(`${ENDPOINT}referral-program.php`, formData, {
               headers: {
                    'content-type': 'multipart/form-data'
               }
          })



          if (res) {
               if (res.data.status === "success") {
                    setrefdata(res.data.data)
               } else {

               }
          }

     }
     const [showModal1, setShowModal1] = useState(false);
     const [showModal2, setShowModal2] = useState(false);
     const [showModal3, setShowModal3] = useState(false);



     return (

          <>
               <div>
                    <Modal centered show={showModal1} onHide={handleCloseModal1}>
                         <Modal.Header closeButton>
                              <Modal.Title>Signups</Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                              <ul>
                                   <li><strong>Total signups : </strong>100</li>

                                   <li><strong>Last Month : </strong>1000 sucessful Signups</li>
                              </ul>
                              .</Modal.Body>
                         <Modal.Footer>
                              <Button variant="secondary" onClick={handleCloseModal1}>
                                   Close
                              </Button>
                         </Modal.Footer>
                    </Modal>

                    {/* Modal 2 */}
                    <Modal centered show={showModal2} onHide={handleCloseModal2}>
                         <Modal.Header closeButton>
                              <Modal.Title>Coupons Earned</Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                              <ul>
                                   <li ><strong>Total Coupons : </strong>50</li>
                                   <li><strong>Top Coupon Code : </strong>AWETECH876</li>
                                   <li><strong>Redemption Rate : </strong>75%</li>
                              </ul>
                              .</Modal.Body>
                         <Modal.Footer>
                              <Button variant="secondary" onClick={handleCloseModal2}>
                                   Close
                              </Button>
                         </Modal.Footer>
                    </Modal>

                    {/* Modal 3 */}
                    <Modal centered show={showModal3} onHide={handleCloseModal3}>
                         <Modal.Header closeButton>
                              <Modal.Title>Money Earned</Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                              <ul>
                                   <li><strong>Total Earnings : </strong>₹10,000</li>
                                   <li><strong>Highest Single Earning : </strong>₹2500</li>
                                   <li><strong>Last Month's Earnings : </strong>₹10,000</li>
                              </ul>
                              .</Modal.Body>
                         <Modal.Footer>
                              <Button variant="secondary" onClick={handleCloseModal3}>
                                   Close
                              </Button>
                         </Modal.Footer>
                    </Modal>
               </div>
               {/* <img className='w-100' src={bg} alt="" /> */}


               <div className=' '>


                    <Helmet>‍
                         <title>Referral Program - Skills Uprise</title>‍
                         <meta name="description" content="Skills Uprise" />
                         <meta name="twitter:card" content="" />
                         <meta name="twitter:site" content="@skillsuprise" />
                         <meta name="twitter:creator" content="@skillsuprise" />
                         <meta name="twitter:title" content="" />
                         <meta name="twitter:description" content="" />
                         <meta name="twitter:image" content="" />
                         <meta property="og:title" content="" />
                         <meta property="og:description" content="" />
                         <meta property="og:image" content="" />
                         <meta property="og:url" content="" />
                         <meta property="og:site_name" content="Skills Uprise" />
                         <meta property="og:locale" content="en_US" />
                         <meta property="og:type" content="article" />
                         <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
                    </Helmet>
                    <div className="position-fixed bottom-60 left-0 w-100 justify-center">
                         <Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
                              <Toast.Header className="bg-warning text-dark">
                                   <img
                                        src="holder.js/20x20?text=%20"
                                        className="rounded bg-warning me-2"
                                        alt=""
                                   />
                                   <strong className="me-auto">Referral code copied</strong>

                              </Toast.Header>
                         </Toast>

                    </div>






                    {refdata ?
                         <>

                              <div className="container-xl position-relative sm-p-15 pt-0  mb-5 pb-5">


                                   <div className="pt-5 container-fluid ">

                                      

                                        {/* my code start */}

                                        <div className="flex-wrap d-flex justify-content-around mb-5">
                                             <div className="col-lg-3 col-md-4 col-sm-12 col-4 p-2 col-12  ">
                                                  <div className="stat-box border border-1 d-flex  br-5  p-3">
                                                       <img className="successful mt-2 rounded-4" src={img1} alt="" />
                                                       <div>
                                                            <h1 className="ms-3">{signups}</h1>
                                                            <h5 className="sm-14 ms-2">Successful Signups</h5>
                                                      

                                                       </div>
                                                  </div>
                                             </div>

                                             <div className="col-lg-3 col-md-4 col-sm-12 col-12 p-2">
                                                  <div className="stat-box border border-1 d-flex br-5  r p-3">
                                                       <img className="successful mt-2 me-1 rounded-4" src={img3} alt="" />
                                                       <div>
                                                            <h1 className="ms-3">{coupons}</h1>
                                                            <h5 className="sm-14 ms-2">Coupons Earned</h5>
                                      
                                                       </div>
                                                  </div>
                                             </div>

                                             <div className="col-lg-3 col-md-4 col-sm-12 col-sm-12 col-12 p-2">
                                                  <div className="stat-box border border-1 d-flex  br-5 text-center p-3">
                                                       <img className="successful mt-2 rounded-4 me-2" src={img2} alt="" />
                                                       <div>
                                                            <h1 className="">₹ {money}</h1>
                                                            <h5 className="sm-14 ms-2">Money Earned</h5>
                                                        


                                                       </div>
                                                  </div>
                                             </div>
                                        </div>

                                        {/* my code ends */}


                                        <div className="text-center referral-title">

                                             <h1 className="sm-25">Earn Upto ₹ 20,000 </h1>
                                             <p className="col-lg-7 mx-auto col-md-8 col-sm-10 col-12">
                                                  Refer your friend to join Skills Uprise and earn exciting  cash rewards</p>



                                        </div>

                                        <div className="mt-3 justify-content-center mb-3">
                                             <div className="text-center">
                                                  <CopyToClipboard onCopy={() => settoast(true)} text={`https://skillsuprise.com/referral=${refdata.referral_code}`}  >

                                                       <Chip label={`https://skillsuprise.com/referral=${refdata.referral_code}`} />

                                                  </CopyToClipboard>
                                                  <br />
                                                  <CopyToClipboard onCopy={() => settoast(true)} text={`https://skillsuprise.com/referral=${refdata.referral_code}`}  >

                                                       <button className="btn mt-3 btn-warning">Copy Referral Code</button>
                                                  </CopyToClipboard>
                                             </div>
                                        </div>




                                   </div>


                                   {/* my code  start*/}
                                   <div className="col-lg-5 mx-auto animated-box p-3 mb-5">
                                        <div className="d-flex align-items-center justify-content-center mx-auto">
                                             <WhatsappShareButton
                                                  size={80}

                                                  className=" d-flex align-items-center"
                                                  round={true}
                                                  url={`https://skillsuprise.com/referral=${refdata.referral_code}`}
                                             >
                                                  <WhatsappIcon size={40} round={true} className="mr-2" />
                                             </WhatsappShareButton>
                                             <LinkedinShareButton
                                                  size={30}
                                                  round={true}
                                                  url={`https://skillsuprise.com/referral=${refdata.referral_code}`}
                                             >
                                                  <LinkedinIcon size={40} round={true} className="mr-2" />
                                             </LinkedinShareButton>
                                             <FacebookShareButton
                                                  className="d-flex align-items-center"
                                                  size={35}
                                                  round={true}
                                                  url={`https://skillsuprise.com/referral=${refdata.referral_code}`}
                                             >
                                                  <FacebookIcon size={40} round={true} className="mr-2" />
                                             </FacebookShareButton>
                                             <TelegramShareButton
                                                  className="d-flex align-items-center"
                                                  size={30}
                                                  round={true}
                                                  url={`https://skillsuprise.com/referral=${refdata.referral_code}`}
                                             >
                                                  <TelegramIcon size={40} round={true} className="mr-2" />
                                             </TelegramShareButton>
                                             <TwitterShareButton
                                                  className="d-flex align-items-center"
                                                  size={30}
                                                  round={true}
                                                  url={`https://skillsuprise.com/referral=${refdata.referral_code}`}
                                             >
                                                  <TwitterIcon size={40} round={true} className="mr-2" />
                                             </TwitterShareButton>
                                             <EmailShareButton
                                                  size={30}
                                                  className="d-flex align-items-center"
                                                  round={true}
                                                  url={`https://skillsuprise.com/referral=${refdata.referral_code}`}
                                             >
                                                  <EmailIcon size={40} round={true} className="mr-2" />
                                             </EmailShareButton>
                                        </div>
                                   </div>
                                   {/* ends */}

                                   <div className="text-center pt-2 pb-3">
                                        <h2 className="sm-25">Rewards & Coupons</h2>
                                   </div>

                              


                          <div className=" col-lg-12">

                                   <ScratchCards />
                              </div> 




                              </div>




                         </>


                         :

                         <>
                              <div className="container-xl">
                                   <LoadingTemplate />
                              </div>

                         </>
                    }


               </div>


          </>


     );
};

export default ReferralProgram;
