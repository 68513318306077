import { Avatar } from '@mui/material'
import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import optionsicon from '../../assets/icons/options.svg'
import { Link } from 'react-router-dom'
import { Close } from '@material-ui/icons';
import ModalStyle from '../../data/ModalStyle';

import { useEffect } from 'react'

import defaultdp from '../../assets/user.png'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FollowUser from '../../actions/connection/FollowUser'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import UnfollowUser from '../../actions/connection/UnfollowUser'
import AcceptRequest from '../../actions/connection/AcceptRequest'
import StudentAccessModal from '../../components/modals/StudentAccessModal'
const ConnectionItem = ({connection,account,access}) => {
const {user} = useContext(AuthContext)
  const [student,setstudent] = useState(access)
const [status,setstatus] = useState(connection.status)

  useEffect(()=>{
if(access){
  setstudent(access)
}
else{
  setstudent(false)
}

  },[])

const [loading,setloading] = useState(false)


const [alert,setalert] = useState(false)

const NewFollowUser = async ()=>{
 //  //console.log("follow "+connection.user_id)
 setloading(true)
  const res = await FollowUser({
    payload:{
      user:user.SessionId,
      following_id:connection.user_id
    }
  })

  if(res){
    setloading(false)
    if(res.status==="success"){
      setstatus(res.following_status)
    }
     //  //console.log("following "+JSON.stringify(res))
  }
}



const NewAcceptRequest = async()=>{
setloading(true)
   //  //console.log("accepting "+connection.user_id)
  const res = await AcceptRequest({
    payload:{
      user:user.SessionId,
      follower_id:connection.user_id
    }
  })

  if(res){
    setloading(false)
    if(res.status==="success"){
      setstatus(res.follower_status)
    }
     //  //console.log("accepted it "+JSON.stringify(res))
  }

}

const NewUnfollowUser = async ()=>{
   //  //console.log("follow "+connection.user_id)
   setloading(true)
    const res = await UnfollowUser({
      payload:{
        user:user.SessionId,
        following_id:connection.user_id
      }
    })
  
    if(res){
      setloading(false)
      if(res.status==="success"){
        setstatus(res.following_status)
      }else{
        
      }
       //  //console.log("following "+JSON.stringify(res))
    }
  }

  const CloseStudentAccessModal = ()=>{
  setalert(false)}
  return (

    <div>
<StudentAccessModal visibility={alert} HandleClose={CloseStudentAccessModal}/>


{student?
<>

<div className="shadow connection  text-center">
         <div className="connection-cover">


{connection.type==="follower"||connection.type==="following"?
<>
<div className="connection-action">
<Dropdown className="dropdown">
  <Dropdown.Toggle className="bg-transparent">
  <img  className="post-action m-0"  src={optionsicon}/>
  
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item href="#/action-1">Copy Post</Dropdown.Item>
   </Dropdown.Menu>
</Dropdown>
</div>
</>:
<>
</>}

         </div>
          <div className="w-50 dp-container mx-auto">
            <Link to={"/u/"+connection.username}>
            
            <img className="avatar" src={connection.profile_pic?connection.profile_pic:defaultdp}></img>
      
            </Link>
       </div>
          <div  className=" connection-info border">
     <Link to={"/u/"+connection.username}  className="info mb-2">
     <h6 className="truncate truncate1">{connection.first_name+" "+connection.last_name}</h6>
        <p className="truncate truncate2 extra-small">{connection.current_position}</p>



     </Link>




<div className="btns pb-3">
{connection.type=="follower"||connection.type=="following"?
<>

<Link to={"/chat/"+connection.conversation_id} className="btn btn-primary btn-sm  w-100">Message</Link>
  


{/*follower or following end */}   
</>:
<>




{connection.type==="request received"?
<>
{/*requests received  start*/}

{status==="pending"?
<>
<button disabled={loading} onClick={()=>NewAcceptRequest()} className="btn btn-primary btn-sm  w-100">{loading?"Accept":"Accepting..."}</button>
  

</>:
<>

{status==="unfollowed"?
<>
<button   onClick={()=>NewFollowUser()}  disabled={loading}  className="btn btn-primary btn-sm  w-100">{loading?"Following...":"Follow"} </button>
 
</>:
<>
<button  className="btn btn-primary btn-sm  w-100" disabled={loading} >{loading?"Cancelling...":"Cancel Invitation"} </button>
 
</>}

</>}





{/*requests received  end*/}

</>:

<>
{/*requests sent  start*/}


{connection.type="request sent"?
<>
{status==="pending"?
<>
<button onClick={()=>NewUnfollowUser()} className="btn btn-primary   btn-sm  w-100" disabled={loading}>{loading?"Withdrawing...":"Withdraw"}</button>
  

</>:
<>
{status==="unfollowed"?
<>
<button  onClick={()=>NewFollowUser()}  className="btn btn-primary btn-sm  w-100" disabled={loading}>{loading?"Following...":"Follow Back"}</button>
  
</>:
<>
<button onClick={()=>NewFollowUser()} className="btn btn-primary btn-sm  w-100" disabled={loading}>{loading?"Following...":"Follow"}</button>
  
</>}
</>}

</>:
<>


</>}



{/*requests sent  end*/}

</>}

{/*requests received or sent end*/}



</>}
       
</div>
     
    
         </div>
     
</div>

</>:
<>
{/*NO ACCESS */}
<div onClick={()=>setalert(true)} className="shadow connection text-center">
         <div className="connection-cover">


{connection.type==="follower"||connection.type==="following"?
<>
<div className="connection-action">
<Dropdown className="dropdown">
  <Dropdown.Toggle className="bg-transparent">
  <img  className="post-action m-0"  src={optionsicon}/>
  
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item >Copy Post</Dropdown.Item>
   </Dropdown.Menu>
</Dropdown>
</div>
</>:
<>
</>}

         </div>
          <div className="w-50 dp-container mx-auto">
            <Link >
            
            <img className="avatar" src={connection.profile_pic?connection.profile_pic:defaultdp}></img>
      
            </Link>
       </div>
          <div  className=" connection-info">
     <Link   className="info mb-2">
     <h6 className="truncate truncate1">{connection.first_name+" "+connection.last_name}</h6>
        <p>{connection.college?connection.college:null}</p>



     </Link>




<div className="btns">
{connection.type=="follower"||connection.type=="following"?
<>

<Link  className="btn btn-primary btn-sm  w-100">Message</Link>
         
</>:
<>
<button className="btn wwer btn-primary btn-sm  w-100">Follow</button>
 

</>}
       
</div>
               {/*you are not a follower & you are not following*/}
             

    
         </div>
     
</div>

</>}
      </div>
    
  )
}

export default ConnectionItem