import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../data/ENDPOINT";
import topimg from "../account/assets/invoiceimgs/top.png";
import bottomimg from "../account/assets/invoiceimgs/bottom (2).png";
import logo from "../account/assets/invoiceimgs/logo.png";
import stamp from "../account/assets/invoiceimgs/stamp.png";

const InvoicePage = () => {
  const { invoice_id } = useParams();

  const params = useParams()
  console.log(params)
  const [invoiceData, setInvoiceData] = useState({});
  // const [userData, setUserData] = useState({});
  // const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const getInvoiceDetails = async () => {
    const formData = new FormData();
    formData.append("invoice_id", invoice_id);

    try {
      const response = await axios.post(ENDPOINT + "get-invoice-details.php", formData, { headers: { "Content-Type": "application/json" } });

      if (response.data && response.data.data) {
        const { user_data, course_enrollment_data, items } = response.data.data;
        setInvoiceData(response.data.data);
        // setUserData(user_data);
        // setItems(items);
        console.log(response.data);

      } else {
        setError("Error fetching invoice data.");
      }
    } catch (err) {
      console.error("Backend error:", err);
      setError("Unable to fetch data from the backend.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoiceDetails();
  }, [invoice_id]);

  return (
    <div className="col-10 d-flex mx-auto m-2 p-3">
      <div className="w-100 p-3 border border-2 rounded-2">
        <div className="d-flex justify-content-end">
          <img className="w-75" src={topimg} alt="img" />
        </div>

        <div className="w-100 top-section d-flex justify-content-between">
          <div className="header1 w-50">
            <img className="w-100" src={logo} alt="img" />
            <p className="m-2">www.skilluprise.com</p>
          </div>
          <div className="w-25 header2 text-start m-3 p2">
            <h3>INVOICE</h3>
            <p>INVOICE ID: {invoiceData.purchase_id || "N/A"}</p>
            <p>
              Date:{" "}
              {invoiceData.purchase_datetime
                ? new Date(invoiceData.purchase_datetime).toLocaleDateString()
                : "N/A"}
            </p>
          </div>

        </div>
        <hr />
        <div className="box border border-2 border-black d-flex justify-content-center justify-content-between w-100">
          <div className="box1 w-50 p-3 m-2">
            <h5>Company</h5>
            <p>
              Aweteck Services Pvt.Ltd 9-41-29/2, First Floor, Beside Bank of
              India, MVP Sector 6, Visakhapatnam 530017
            </p>
          </div>
          <div className="box2 w-50 p-3 m-2">
            <h5>Customer</h5>
            <p>
              {invoiceData.first_name} {invoiceData.last_name}
              <br />
              {invoiceData.address}
            </p>
          </div>
        </div>

        <div className="container m-2 w-100 mt-5 d-flex justify-content-center">
          <table className="table text-center">
            <thead className="thead-light">
              <tr>
                <th>NO.</th>
                <th>ITEM</th>
                <th>PEOPLE</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
           
                  <tr >
                    <td className="bg-secondary text-white">1</td>
                    <td className="bg-secondary text-white">{invoiceData.course_url}</td>
                    <td className="bg-secondary text-white">1</td>
                    <td className="bg-secondary text-white">₹ {invoiceData.purchase_price - (invoiceData.purchase_price * 18) / 100}</td>
                  </tr>
         
            </tbody>

          </table>
        </div>

        <div className="d-flex justify-content-between w-100 flex-wrap">
          <div className="mt-5 w-50">
            <h3>Note:</h3>
            <p>
              We prioritize customer satisfaction. Our team is dedicated to
              providing exceptional service and ensuring your safety.
            </p>
          </div>
          <div className="w-50">
            <div className="heds d-flex justify-content-between mt-5 p-2">
              <div className="text-start">
                <h6>Subtotal</h6>
                <h6 className="mt-2">Tax(18%)</h6>
              </div>
              <div className="text-start">
                <p>₹ {invoiceData.purchase_price - (invoiceData.purchase_price * 18) / 100}</p>
                <p className="mt-1">₹ {(invoiceData.purchase_price * 18) / 100}</p>
              </div>
            </div>
            <div className="totals bg-secondary text-white d-flex justify-content-between p-2">
              <div className="text-end">
                <h6>TOTAL</h6>
              </div>
              <div className="text-start">
                <h6>₹ {invoiceData.purchase_price }</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <img src={stamp} alt="img" />
        </div>
        <div className="end text-center mb-3">
          <p>Aweteck Services Pvt.Ltd</p>
          <p>
            9-41-29/2, First Floor, Beside Bank of India, MVP Sector 6,
            Visakhapatnam, 530017
          </p>
          <p>Contact: +91-9490174876 | Email: contact@skillsuprise.com</p>
        </div>
        <img className="w-100" src={bottomimg} alt="img" />
      </div>
    </div>
  );
};

export default InvoicePage;
